<template>
    <div class="google-auth">
        <b-row v-if="tokens.length > 0 && validTokens.length > 0">
            <b-col md="8">
                <wit-select
                    class="text-nowrap"
                    v-model="token"
                    placeholder="Select Account"
                    :options="validTokens"
                    id="token-multiselect"
                    @input="emitInput"
                    :disabled="disabled"
                    label="owner"
                    :filterBy="filterBy"
                >
                    <div slot="option" slot-scope="option">
                        <span>{{ `${option.owner.name} (${option.owner.email})` }}</span>
                    </div>
                    <div slot="selected-option" slot-scope="option">
                        <span>{{ `${option.owner.name} (${option.owner.email})` }}</span>
                    </div>
                </wit-select>
                <Feedback
                    :state="validateRef('token')"
                    invalid="This field is required"
                    valid="Token is valid"
                ></Feedback>
            </b-col>
            <b-col md="8">
                <span class="google-reauthorize google-auth-note">
                    or
                    <img
                        @click="authorize"
                        src="@/assets/gcp-icons/AuthorizeButton.png"
                        v-bind:class="{'pointer-disabled': disabled}"
                    />
                    to use a different Account
                </span>
            </b-col>
        </b-row>
        <b-row v-if="tokens.length > 0 && validTokens.length === 0">
            <b-col md="8">
                <span class="google-auth-note">
                    <img
                        @click="authorize"
                        class="auth-required"
                        src="@/assets/gcp-icons/AuthorizeButton.png"
                        v-bind:class="{'pointer-disabled': disabled}"
                    />
                    <br />None of Your tokens meet the requirements. You have to authorize again to extend the scope.
                </span>
            </b-col>
        </b-row>
        <b-row v-if="tokens.length === 0">
            <b-col md="8">
                <span class="google-auth-note">
                    <img
                        @click="authorize"
                        class="auth-required"
                        src="@/assets/gcp-icons/AuthorizeButton.png"
                        v-bind:class="{'pointer-disabled': disabled}"
                    />
                    <br />You do not seem to have given us access to Google APIs. You have to authorize first to select an
                    authorized account.
                </span>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

import Feedback from '@/components/Feedback.vue'

export default {
    components: {Feedback},
    props: {
        value: {
            type: String | null,
            required: true,
        },
        requestedScopes: {
            type: Array,
        },
        scope: {
            type: Array,
            required: true,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        projectId: {
            type: String | null,
        },
    },
    mixins: [validationMixin],
    validations: {
        token: {required},
    },
    watch: {
        value() {
            this.token = this.tokens.find(el => el.id === this.value)
        },
        async projectId() {
            await this.$store.dispatch('token/fetchTokens', this.projectId)
            this.token = this.tokens.find(el => el.id === this.value)
        },
    },
    async created() {
        await this.$store.dispatch('token/fetchTokens', this.projectId)
        this.token = this.tokens.find(el => el.id === this.value)
    },
    data() {
        return {
            token: null,
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            tokens: 'token/googleTokens',
        }),
        validTokens() {
            return this.tokens.filter(token =>
                this.scope.every(requiredScope => token.token.scope.includes(requiredScope))
            )
        },
    },
    methods: {
        emitInput() {
            if (this.token) {
                this.$emit('input', this.token.id)
            } else {
                this.$emit('input', null)
            }
        },
        authorize() {
            this.$store.commit('loading/PROCESSING', `Authorizing...`)

            const options = {
                scope: this.requestedScopes,
                projectId: this.projectId ? this.projectId : this.activeProject.id,
            }

            this.$store
                .dispatch(`google/upsertToken`, options)
                .then(async response => {
                    await this.$store.dispatch('token/fetchTokens', this.projectId)
                    this.$store.commit('loading/PROCESSED')
                })

                .catch(exception => {
                    this.$store.commit('loading/PROCESSED')
                    this.$errorHandler.report(exception, 'Could not upsert Google token')
                })
        },
        validateRef(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            return validateField.$dirty ? !validateField.$invalid : null
        },
        filterBy(option, label, search) {
            return `${option.owner.name} (${option.owner.email})`.toLowerCase().indexOf(search.toLowerCase()) > -1
        },
    },
}
</script>

<style lang="scss">
.google-reauthorize {
    opacity: 0.8;
}
.google-auth {
    .google-auth-note {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.7rem;
        img {
            width: 150px;
        }

        img.pointer-disabled {
            filter: grayscale(70%);
        }
    }
}
</style>
